<template>
  <h1 v-if="data.size === 1" v-html="data.title"/>
  <h2 v-else-if="data.size === 2" v-html="data.title"/>
  <h3 v-else-if="data.size === 3" v-html="data.title"/>
  <h4 v-else-if="data.size === 4" v-html="data.title"/>
  <h5 v-else-if="data.size === 5" v-html="data.title"/>
  <h6 v-else-if="data.size === 6" v-html="data.title"/>
</template>

<script>
  export default {
    props: ['data'],
    name: 'TitleBlock',
  }
</script>